@font-face {
  font-family: 'Sharp Grotesk Medium';
  src: url('https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/SharpGroteskMedium25.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/WorkSansRegular.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans Medium';
  src: url('https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/WorkSansMedium.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded Medium';
  font-display: swap;
  src: url('https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/GothamRounded-Medium.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded Book';
  font-display: swap;
  src: url('https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/GothamRounded-Book.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Rounded Book Italic';
  font-display: swap;
  src: url('https://bloomio-public.s3.us-west-2.amazonaws.com/assets/fonts/GothamRounded-BookItalic.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}
